<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="700"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-form ref="form">
          <v-card class="pa-5">
            <v-card-title>
              <h2>{{ formTitle }}</h2>
            </v-card-title>

            <v-card-text class="pb-0">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model.trim="coupon.title"
                          label="クーポン名"
                          counter="30"
                          required
                          :rules="[valiRules.required, valiRules.max30]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-switch
                          v-model="coupon.is_active"
                          inset
                          label="アクティブ"
                          color="primary"
                          persistent-hint
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model.trim="coupon.description"
                      label="説明"
                      counter="60"
                      required
                      :rules="[valiRules.required, valiRules.max60]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="10">
                        <!-- 画像ファイル -->
                        <v-file-input
                          v-model="file"
                          accept="image/png, image/jpeg"
                          outlined chips show-size
                          prepend-icon="mdi-image"
                          label="クーポン画像"
                          hint="サイズ上限：1MB" persistent-hint
                          :rules="formType === 'create' ? [valiRules.required, valiRules.image] : [valiRules.image]"
                        >
                          <template v-slot:selection="{ text }">
                            <v-chip
                              color="primary"
                              label small
                            >
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="2"
                        class="py-0 d-flex flex-column align-center justify-start"
                        v-if="coupon.image_url"
                      >
                        <small>設定中の画像</small>
                        <v-img
                          max-height="50"
                          contain
                          :src="coupon.image_url"
                        >
                        </v-img>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model.trim="coupon.discount"
                          type="number"
                          max="99999"
                          required
                          label="割引き額（円）"
                          :rules="[valiRules.required, valiRules.price]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model.trim="coupon.max_use_count"
                          type="number"
                          max="9999"
                          :label="isInfinite ? '無制限に設定' : '利用可能な回数'"
                          :disabled="isInfinite"
                          :required="!isInfinite"
                          :rules="!isInfinite ? [valiRules.required, valiRules.min1num] : []"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-switch
                          v-model="isInfinite"
                          inset
                          label="無制限"
                          color="primary"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row justify="start" align="center">
                      <v-col cols="3">
                        <v-menu top
                          v-model="openPickerFrom"
                          :close-on-content-click="false"
                          :nudge-left="60"
                          transition="scroll-y-reverse-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="pickerDete"
                              label="利用開始日"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="pickerDete"
                            locale="ja"
                            no-title
                            :day-format="date => numericDate(date)"
                            header-color="primary"
                            event-color="primary"
                            @input="openPickerFrom = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- 開始時刻 -->
                      <v-col cols="2">
                        <v-menu top
                          v-model="openPickerTime"
                          :close-on-content-click="false"
                          :nudge-left="100"
                          transition="scroll-y-reverse-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="pickerTime"
                              label="時刻"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="pickerTime"
                            format="24hr"
                            header-color="primary"
                            color="primary"
                            @click:minute="openPickerTime = false"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                      <span class="mx-2">から</span>
                      <v-col cols="3">
                        <v-menu top
                          v-model="openPickerTo"
                          :close-on-content-click="false"
                          :nudge-left="60"
                          transition="scroll-y-reverse-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="pickerDeteTo"
                              label="有効期限日"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="pickerDeteTo"
                            locale="ja"
                            no-title
                            :day-format="date => numericDate(date)"
                            header-color="primary"
                            event-color="primary"
                            @input="openPickerTo = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- 終了時刻 -->
                      <v-col cols="2">
                        <v-menu top
                          v-model="openPickerTimeTo"
                          :close-on-content-click="false"
                          :nudge-left="100"
                          transition="scroll-y-reverse-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="pickerTimeTo"
                              label="時刻"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="pickerTimeTo"
                            format="24hr"
                            header-color="primary"
                            color="primary"
                            @click:minute="openPickerTimeTo = false"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :disabled="!isFormChanged"
                color="primary"
                @click="formSubmitted"
              >
                {{ formButton }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>

            <!-- ローダー -->
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute top
              color="primary"
            ></v-progress-linear>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import moment from 'moment'
import { ValidationRules } from '@/module.js'

export default {
  components: {},

  props: {
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      pickerDete: '',
      pickerDeteTo: '',
      openPickerFrom: false,
      openPickerTo: false,
      openPickerTime: false,
      openPickerTimeTo: false,
      pickerTime: '',
      pickerTimeTo: '',
      coupon: {},
      isInfinite: false,
      file: null,
      openForm: false,
      isFormChanged: false,
      formType: '',
      comeBack: {},
      submitCallback: null,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    }
  },

  computed: {
    formTitle() {
      return this.formType === 'create' ? '新規クーポン登録' : 'クーポン情報更新'
    },
    formButton() {
      return this.formType === 'create' ? '登録' : '更新'
    },
    numericDate() {
      return date => moment(date).format('D')
    }
  },

  watch: {
    openForm: function(now, old) {
      if (now !== old) {
        this.isFormChanged = false
      }
    },
    coupon: {
      handler: function(now, old) {
        if (Object.keys(old).length && now.coupon_id === old.coupon_id) {
          this.isFormChanged = true
        }
      },
      deep: true
    },
    'pickerDete': function(now, old) {
      if (now && old && now !== old) {
        this.isFormChanged = true
      }
    },
    'pickerDeteTo': function(now, old) {
      if (now && old && now !== old) {
        this.isFormChanged = true
      }
    },
    'pickerTime': function(now, old) {
      if (now && old && now !== old) {
        this.isFormChanged = true
      }
    },
    'pickerTimeTo': function(now, old) {
      if (now && old && now !== old) {
        this.isFormChanged = true
      }
    },
    isInfinite: function(now) {
      this.isFormChanged = true
      if (now) {
        this.coupon.max_use_count = null
      }
    },
  },

  methods: {
    open(handdownData) {
      this.formType = handdownData.formType
      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack
      this.snackbar.message = ''

      if (this.formType === 'create') {
        this.coupon = {
          is_active: true,
          title: '',
          description: '',
          discount: 1000,
          max_use_count: 1,
          image_url: ''
        }
        this.isInfinite = false
        this.pickerDete = moment(new Date()).format('YYYY-MM-DD')
        this.pickerDeteTo = moment(new Date()).format('YYYY-MM-DD')
        this.pickerTime = "00:00"
        this.pickerTimeTo = "00:00"
      } else {
        this.coupon = {
          coupon_id: handdownData.updateData.coupon_id,
          is_active: handdownData.updateData.is_active,
          title: handdownData.updateData.title,
          description: handdownData.updateData.description,
          discount: handdownData.updateData.discount,
          max_use_count: handdownData.updateData.max_use_count,
          image_url: handdownData.updateData.image_url
        }
        this.pickerDete = moment(handdownData.updateData.start_date).format('YYYY-MM-DD')
        this.pickerDeteTo = moment(handdownData.updateData.end_date).format('YYYY-MM-DD')
        this.pickerTime = moment(handdownData.updateData.start_date).format('HH:mm')
        this.pickerTimeTo = moment(handdownData.updateData.end_date).format('HH:mm')
        this.isInfinite = handdownData.updateData.max_use_count == 0 ? true : false
      }
      this.isFormChanged = false
      this.openForm = true
    },

    close() {
      this.openPickerFrom = false
      this.openPickerTo = false
      this.openPickerTime = false
      this.openPickerTimeTo = false
      this.submitCallback = null
      this.loading = false
      this.isFormChanged = false
      this.openForm = false
      this.file = null
    },

    formSubmitted() {
      //バリデーション
      if (!this.$refs['form'].validate()) {
        this.snackbar.message = $literals.MESSAGE.validationFormInput
      }
      else if (moment(this.pickerDete + ' ' + this.pickerTime).
              isSameOrAfter(this.pickerDeteTo + ' ' + this.pickerTimeTo)) {
        this.snackbar.message = $literals.MESSAGE.warningInvalidDateRange
      }
      else {
        this.snackbar.message = ''
      }
      if (this.snackbar.message) {
        this.snackbar.color = 'warning'
        this.snackbar.open = true
        return
      }

      this.loading = true

      const formData = new FormData()
      formData.append('shop_id', this.shopData.shop_id)
      formData.append('coupon_id', this.coupon.coupon_id || 0)
      formData.append('is_active', this.coupon.is_active)
      formData.append('title', this.coupon.title)
      formData.append('description', this.coupon.description)
      formData.append('image_url', this.file || this.coupon.image_url)
      formData.append('discount', this.coupon.discount)
      formData.append('max_use_count', this.isInfinite ? 0 : this.coupon.max_use_count)
      formData.append('start_date', moment(this.pickerDete + ' ' + this.pickerTime).format('YYYY-MM-DD HH:mm'))
      formData.append('end_date', moment(this.pickerDeteTo + ' ' + this.pickerTimeTo).format('YYYY-MM-DD HH:mm'))

      this.submitCallback(formData, this.comeBack)
    }
  }
}
</script>

<style scoped>
</style>
