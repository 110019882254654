<template>
  <v-container fluid class="content-wrap">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[{label:'クーポン', icon:'plus-box-multiple', tip:'新規クーポン追加', event:'click'}]"
      @click="openFormRegister('create')"
    >
      <template v-slot:content-info>
        ターゲットリーチ：{{ targetreach }} ／ 当月配信済：{{ consumption }}
      </template>
    </info-bar>

    <!-- コンテンツボディー（クーポンリスト） -->
    <v-row class="pa-3">
      <v-col v-if="!coupons.length" >
        <v-card flat>
          <v-card-text>
            表示するデータがありません。<br />
            画面右上の「クーポン<v-icon>mdi-plus-box-multiple</v-icon>」からクーポンを作成してください。
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4"
        :class="{ inactive: !coupon.is_active }"
        v-for="(coupon, index) in coupons"
        :key="coupon.coupon_id"
      >
        <v-card>
          <figure>
            <v-img
              contain
              max-height="300"
              :src="coupon.image_url"
              :title="coupon.title"
              @click="openFormRegister('update', index)"
            >
            </v-img>
          </figure>

          <v-card-title class="pb-2">
            <h3>{{ coupon.title }}</h3>
          </v-card-title>

          <v-card-text>
            <p class="pb-2">『{{ coupon.description }}』</p>
            <p>割引き額：{{ coupon.discount }}円</p>
            <p>
              有効期限：<span>{{ formatDate(coupon.start_date) }}</span> 〜
              <span>{{ formatDate(coupon.end_date) }}</span>
            </p>
            <p>最大利用回数：{{ couponUseMax(coupon.max_use_count) }}</p>
            <p>通算利用統計：{{ coupon.use_count }}回</p>
          </v-card-text>

          <!-- スピードダイヤル -->
          <v-speed-dial class="mb-n2 mr-n2"
            v-model="coupon.btn"
            absolute bottom right open-on-hover
            direction="left"
            transition="slide-x-reverse-transition"
          >
            <template v-slot:activator>
              <v-btn
                v-model="coupon.btn"
                fab small
                color="primary"
              >
                <v-icon v-if="coupon.btn">mdi-close</v-icon>
                <v-icon v-else>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-btn
              fab small
              color="primary"
              @click="openFormRegister('update', index)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <btn-tip
              tip="一括配信" icon="cellphone-nfc"
              fab small
              elevation="7"
              color="primary"
              @click="openModalBroadcast(coupon)"
            ></btn-tip>
            <btn-tip
              tip="指定配信" label="グ" icon="cellphone-nfc"
              fab small
              elevation="7"
              color="primary"
              @click="openFormMulticast(coupon)"
            ></btn-tip>
            <v-btn
              fab small
              color="primary"
              @click="openModalDelete(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <!-- <btn-tip
              tip="Comming Soon!!" icon="chart-bar"
              fab xsmall ismall
            ></btn-tip> -->
          </v-speed-dial>

          <!-- 非表示オーバーレイ -->
          <v-fade-transition>
            <v-overlay
              v-if="!coupon.is_active"
              absolute
              z-index="2"
              color="accent"
            >
              <v-btn
                color="primary"
                @click="activateCoupon(coupon)"
              >アクティブにする</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </v-col>
    </v-row>

    <!-- クーポン作成フォーム -->
    <form-register
      ref="formRegister"
      :shopData="shopData"
    ></form-register>

    <!-- 指定配信フォーム -->
    <form-multicast
      ref="formMulticast"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      @reset="$emit('reset')"
    >
    </form-multicast>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete">
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="true"
    >
      {{ loadingMessage }}
    </loader>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import ModalDelete from '@/components/_ModalDelete.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import FormMulticast from '@/components/_FormMulticast.vue'
import FormRegister from '@/components/CouponFormRegister.vue'

export default {
  components: {
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'btn-tip': BtnWithTip,
    'modal-confirm': ModalConfirm,
    'modal-delete': ModalDelete,
    'overlay-message': OverlayMessage,
    'form-multicast': FormMulticast,
    'form-register': FormRegister,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      isLineIntegrated: false,
      coupons: [],
      targetreachNum: 0,
      consumptionNum: 0,
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    consumption() {
      return typeof this.consumptionNum === 'number'
        ? '約' + this.consumptionNum + '通'
        : this.consumptionNum
    },
    targetreach() {
      return typeof this.targetreachNum === 'number'
        ? '約' + this.targetreachNum + '名'
        : this.targetreachNum
    },
    couponUseMax() {
      return maxCount => (maxCount <= 0 ? '無制限' : maxCount + '回')
    },
    couponUseCount() {
      return useCount => useCount || 0
    },
    formatDate() {
      return date => {
        if (moment(date).format('YYYY') === moment(new Date()).format('YYYY')) {
          return moment(date).format('M月D日 HH:mm')
        } else {
          return moment(date).format('YYYY年M月D日 HH:mm')
        }
      }
    },
    mdiIcon() {
      return name => 'mdi-' + name
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    } else {
      this.adminApi.setToken(this.serverToken)

      this.loading = true
      this.loadingMessage = 'クーポンデータ取得中・・・'

      this.getData()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false)
    }
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {
      await this.adminApi.getReqWithAuth('line-integration/').then( result => {
        this.isLineIntegrated = result.shop_id !== 0
      })

      if (this.isLineIntegrated) {
        await Promise.all([
          this.getCoupons(),
          this.getMonthlyConsumption(),
          this.getTargetreach(),
        ])
      } else {
        this.modalMessage = '<p>こちらの機能はLINE連携を設定してから利用可能になります。<br />'+
                            'サイドメニューの「店舗基本設定」→「LINE連携」から設定をしてください。</p>'
        this.$refs.overlayMessage.open()
      }
    },

    //新規作成＆更新クリック
    openFormRegister(type, index) {
      const callback = type === 'update' ? this.updateCoupon : this.createCoupon
      const formHanddown = {
        formType: type,
        updateData: this.coupons[index],
        submitCallback: callback,
        comeBack: { index: index }
      }
      this.$refs.formRegister.open(formHanddown)
    },

    //削除クリック
    openModalDelete(index) {
      this.modalMessage = '<p>以下のクーポンを削除してよろしいですか？</br></br>' + '「' + this.coupons[index].title + '」</p>'

      const modalHanddown = {
        submitCallback: this.deleteCoupon,
        comeBack: { index: index }
      }
      this.$refs.modalDelete.open(modalHanddown)
    },

    //一括配信クリック
    openModalBroadcast(coupon) {
      this.modalMessage = '<p>クーポン「' + coupon.title + '」を配信してよろしいですか？</p>'

      const modalHanddown = {
        yesCallback: this.broadcastCoupon,
        comeBack: { coupon: coupon },
        buttonLabel: '配信する',
      }
      this.$refs.modalConfirm.open(modalHanddown)
    },

    //指定配信クリック
    openFormMulticast(coupon) {
      const formHanddown = {
        submitCallback: this.multicastCoupon,
        comeBack: { coupon: coupon },
      }
      this.$refs.formMulticast.open(formHanddown)
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //既存クーポン取得
    //**************************************************
    getCoupons() {
      return this.adminApi.getReqWithAuth('coupon/').then( results => {
        if (!results || !results.length) return
        this.coupons = results
      })
    },

    //**************************************************
    //クーポン新規作成
    //**************************************************
    createCoupon(formData) {
      this.adminApi.apiReqWithData('POST', 'coupon/create/', formData).then( response => {
        this.coupons.unshift(response)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formRegister.close() )
    },

    //**************************************************
    //クーポン更新
    //**************************************************
    updateCoupon(formData, cameBackData) {
      const apiPartial = 'coupon/update/' + this.coupons[cameBackData.index].coupon_id

      this.adminApi.apiReqWithData('PUT', apiPartial, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
          for (let prop in response) {
            if (prop) this.coupons[cameBackData.index][prop] = response[prop]
          }
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formRegister.close() )
    },

    //**************************************************
    //クーポン削除
    //**************************************************
    deleteCoupon(cameBackData) {
      const apiPartial = 'coupon/delete/' + this.coupons[cameBackData.index].coupon_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        this.coupons.splice(cameBackData.index, 1)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    },

    //**************************************************
    //アクティベートクーポン
    //**************************************************
    activateCoupon(coupon) {
      const apiPartial = 'coupon/update/' + coupon.coupon_id + '/is_active'
      const payload = JSON.stringify({value: true})

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        coupon.is_active = true
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //括配信
    //**************************************************
    broadcastCoupon(cameBackData) {
      const apiPartial = 'coupon/broadcast/' + cameBackData.coupon.coupon_id

      this.adminApi.apiReqWithData('POST', apiPartial).then( result => {
        if (result === -1) {
          this.snackbar = {...{color:'error', message: $literals.MESSAGE.unsetLineIntegration, open: true}}
        } else if (result > 0) {
          this.snackbar = {...{color:'success', message: '一括配信完了しました\nターゲットリーチ：約' + result + '名', open: true}}
        } else {
          this.snackbar = {...{color:'info', message: '一括配信完了しました', open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalConfirm.close() )
    },

    //**************************************************
    //指定配信
    //**************************************************
    multicastCoupon(cameBackData) {
      const apiPart = 'coupon/multicast/' + cameBackData.coupon.coupon_id
      const payload = JSON.stringify(cameBackData.customerGroups)

      this.adminApi.apiReqWithData('POST', apiPart, payload).then( result => {
        if (result === -1) {
          this.snackbar = {...{color:'error', message: $literals.MESSAGE.unsetLineIntegration, open: true}}
        } else if (result > 0) {
          this.snackbar = {...{color:'success', message: '指定配信完了しました\nターゲットリーチ：約' + result + '名', open: true}}
        } else {
          this.snackbar = {...{color:'info', message: '指定配信完了しました', open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formMulticast.close() )
    },

    //**************************************************
    //当月送信数取得
    //**************************************************
    getMonthlyConsumption() {
      return this.adminApi.getReqWithAuth('line/consumption/').then( result => {
        this.consumptionNum = result
      })
      .catch(() => this.consumptionNum = '取得失敗' )
    },

    //**************************************************
    //ターゲットリーチ取得
    //**************************************************
    getTargetreach() {
      return this.adminApi.getReqWithAuth('line/targetreach/').then( result => {
        this.targetreachNum = result
      })
      .catch(() => this.targetreachNum = '取得失敗' )
    }
  }
}
</script>

<style scoped>
</style>
